import React, { useEffect } from 'react';

import { BaseDraggableList } from './BaseDraggableList';
import { useListData } from 'react-stately';

export interface DraggableListProps {
  items: any[] | any;
  disabledKeys?: string[] | number[];
  showDeleteButtons?: boolean;
  onReorder?: (e, updatedList) => void;
}

export const DraggableList = (props: DraggableListProps) => {
  const [latestDropEvent, setLatestDropEvent] = React.useState(null);
  const [reOrdered, setReOrdered] = React.useState(false);

  const list = useListData({ initialItems: props.items });

  // Using a bit tedious way to update data as the list does not like to be updated directly.
  useEffect(() => {
    list.items.forEach((item) => {
      list.remove(item.key);
    });

    props.items.forEach((item) => {
      list.append(item);
    });
  }, [props.items]);

  // Make sure that the parent component gets the updated list when the list is reordered.
  useEffect(() => {
    if (reOrdered) {
      props.onReorder(latestDropEvent, list.items);
      setReOrdered(false);
    }
  }, [list.items]);

  const onReorder = (e) => {
    const dropEvent = e;
    setLatestDropEvent(dropEvent);
    switch (dropEvent.target.dropPosition) {
      case 'before':
        list.moveBefore(dropEvent.target.key, dropEvent.keys);
        break;
      case 'after':
        list.moveAfter(dropEvent.target.key, dropEvent.keys);
        break;
      case 'on':
        // Just here for illustration purposes. Object was not moved.
        break;
      default:
        break;
    }
    setReOrdered(true);
    props.onReorder(dropEvent, list.items);
  };

  return <BaseDraggableList {...props} items={list.items} onReorder={onReorder} />;
};
