import React from 'react';

import {
  ListDropTargetDelegate,
  ListKeyboardDelegate,
  mergeProps,
  useDraggableCollection,
  useDroppableCollection,
  useListBox
} from 'react-aria';

import {
  DraggableCollectionStateOptions,
  DroppableCollectionStateOptions,
  ListProps,
  useDraggableCollectionState,
  useDroppableCollectionState,
  useListState
} from 'react-stately';

import { ReorderableOption } from './ReorderableOption';

export interface DraggableListProps
  extends Omit<DraggableCollectionStateOptions, 'collection'>,
    Omit<DroppableCollectionStateOptions, 'collection'>,
    ListProps<object> {
  showDeleteButtons?: boolean;
}

export const BaseDraggableList: React.FC<DraggableListProps> = (props) => {
  const state = useListState(props);
  const ref = React.useRef(null);

  // Setup drag state for the collection.
  const dragState = useDraggableCollectionState({
    // Pass through events from props.
    ...props,

    // Collection and selection manager come from list state.
    collection: state.collection,
    selectionManager: state.selectionManager,

    // Provide data for each dragged item. This function could
    // also be provided by the user of the component.
    getItems:
      props.getItems ||
      ((keys) => {
        return [...keys].map((key) => {
          const item = state.collection.getItem(key);

          return {
            'text/plain': item.textValue
          };
        });
      })
  });

  useDraggableCollection(props, dragState, ref);

  // Setup react-stately and react-aria hooks for dropping.
  const dropState = useDroppableCollectionState({
    ...props,
    collection: state.collection,
    selectionManager: state.selectionManager
  });

  const { listBoxProps } = useListBox(
    {
      ...props,
      // Prevent dragging from changing selection.
      shouldSelectOnPressUp: true
    },
    state,
    ref
  );

  const { collectionProps } = useDroppableCollection(
    {
      ...props,
      // Provide drop targets for keyboard and pointer-based drag and drop.
      keyboardDelegate: new ListKeyboardDelegate(state.collection, state.disabledKeys, ref),
      dropTargetDelegate: new ListDropTargetDelegate(state.collection, ref)
    },
    dropState,
    ref
  );

  return (
    <ul {...mergeProps(listBoxProps, collectionProps)} ref={ref}>
      {[...state.collection].map((item) => (
        <ReorderableOption
          key={item.key}
          item={item}
          state={state}
          dragState={dragState}
          dropState={dropState}
          disabled={state.disabledKeys.has(item.key)}
          showDeleteButtons={props.showDeleteButtons}
        />
      ))}
    </ul>
  );
};
