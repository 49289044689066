import React, { FC, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { translations } from '@/locales';

import { ComboBox } from '@nshift/common/components/Select';
import { Icon } from '@nshift/common/components/Icon';
import { PrinterProfileGroupPriorityList } from './PrinterProfileGroupPriorityList';

import type { PrinterGroup, ApiFormattedPrinterGroup } from './priorities/types';
import { getActiveGroup, tempProfileName } from './priorities/utils';
import classNames from 'classnames';

interface Props {
  profileName?: string;
  initialGroups?: Array<PrinterGroup>;
}

export const PrinterProfileGroups: FC<Props> = ({ initialGroups = [], profileName }) => {
  const intl = useIntl();

  const [groups, setGroups] = useState<PrinterGroup[]>(initialGroups);
  const [selectedGroupIds, setSelectedGroupIds] = useState<Set<string>>(new Set([]));
  const [activeGroupId, setActiveGroupId] = useState<string | null>();
  const [updatedPrinterGroups, setUpdatedPrinterGroups] = useState<ApiFormattedPrinterGroup[]>([]);

  useEffect(() => {
    setGroups(initialGroups);
  }, [initialGroups]);

  const nextId = useMemo(() => {
    return groups?.length > 0
      ? groups
          .map(({ id }) => id)
          .sort()
          .reverse()[0] + 1
      : 0;
  }, [groups]);

  const selectedGroups = useMemo(() => {
    const filteredGroups = groups.filter(({ id }) => selectedGroupIds.has(String(id)));
    return filteredGroups.map((group) => {
      const { profilesAndPriorities = [] } = group;
      const isProfileInGroup = profilesAndPriorities?.find(({ name }) => profileName === name);
      let updatedprofilesAndPriorities = profilesAndPriorities;

      if (!isProfileInGroup) {
        // Get lowest priority in group
        const lowestPriority = Math.min(...profilesAndPriorities.map(({ priority }) => priority)) || -1;

        updatedprofilesAndPriorities = [
          ...(group.profilesAndPriorities || []),
          {
            name: profileName || tempProfileName(nextId),
            priority: lowestPriority + 1
          }
        ];
      }
      return {
        ...group,
        profilesAndPriorities: updatedprofilesAndPriorities
      };
    });
  }, [groups, selectedGroupIds, activeGroupId, profileName, updatedPrinterGroups]);

  const addNonExistingGroup = (inputValue: string) => {
    // Both add new value to selectedGroupIds and also to the selectable choices in the combobox
    // The new group does not currently have an id at this stage
    setSelectedGroupIds((prev) => new Set(prev.add(String(nextId))));
    setGroups((prev) => [
      ...prev,
      {
        id: nextId,
        name: inputValue,
        profilesAndPriorities: []
      }
    ]);
  };

  const storeGroupForApiRequest = (newPriority: number) => {
    const activeGroup = getActiveGroup(selectedGroups, activeGroupId ?? null);
    const indexForGroup = updatedPrinterGroups.findIndex((group) => group.groupName === activeGroup?.name);
    if (indexForGroup !== -1) {
      const clonedUpdatedPrinterGroups = [...updatedPrinterGroups];
      clonedUpdatedPrinterGroups[indexForGroup].priority = newPriority;
      setUpdatedPrinterGroups(clonedUpdatedPrinterGroups);
    } else {
      // Add the group to the updatedPrinterGroups
      setUpdatedPrinterGroups((prev) => [
        ...prev,
        {
          groupName: activeGroup?.name || '',
          priority: newPriority
        }
      ]);
    }
  };

  const handleGroupSelectionChange = (newSelectedGroupIds: Set<string>) => {
    setSelectedGroupIds(newSelectedGroupIds);
    if (newSelectedGroupIds.size > 0) {
      const lastSelectedGroupId = Array.from(newSelectedGroupIds).pop();
      setActiveGroupId(lastSelectedGroupId);
    }
    // Also store the newly selected group and priority list for the api request later
    // Get the new priority for the profile in the selected group
    const currentGroupPriorityForProfile = getActiveGroup(
      selectedGroups,
      activeGroupId ?? null
    )?.profilesAndPriorities?.find(({ name }) => name === profileName)?.priority;
    if (currentGroupPriorityForProfile) {
      storeGroupForApiRequest(currentGroupPriorityForProfile);
    }
  };

  const removeGroupFromProfile = (groupId: string) => {
    // Remove group from selectedGroupIds
    setSelectedGroupIds((prev) => {
      return new Set(Array.from(prev).filter((id) => id !== groupId));
    });
    // Also remove group from updatedPrinterGroups
    const groupName = groups.find(({ id }) => groupId === String(id))?.name;

    const newUpdatedPrinterGroups = updatedPrinterGroups.filter((updatedGroup) => {
      return updatedGroup.groupName !== groupName;
    });
    setUpdatedPrinterGroups(newUpdatedPrinterGroups);
  };

  const updatePositionInGroup = (
    activeGroup: PrinterGroup | null,
    updatedPriorityList: Array<{ name: string; priority: number }>
  ) => {
    const clonedGroups = [...groups];

    // Find the group in the clonedGroups using id
    const indexForGroup = clonedGroups.findIndex(({ id }) => activeGroup?.id === id);

    if (indexForGroup !== -1) {
      // Update the priority of the profile in the group
      clonedGroups[indexForGroup].profilesAndPriorities = updatedPriorityList.map(({ name, priority }) => ({
        name,
        priority
      }));
      setGroups(clonedGroups);
    }
  };

  const onPriorityReorder = (newPriority: any, updatedPriorityList: Array<{ name: string; priority: number }>) => {
    // Add or update the priority of the profile in the group
    updatePositionInGroup(getActiveGroup(selectedGroups, activeGroupId ?? null), updatedPriorityList);

    storeGroupForApiRequest(newPriority);
  };

  return (
    <div>
      <ComboBox
        label={intl.formatMessage({ id: translations.pages.printerProfiles.group.title })}
        items={groups}
        selectedKeys={Array.from(selectedGroupIds)}
        allowCustomValues
        selectionMode="multiple"
        onSelectionChange={(ids) => handleGroupSelectionChange(ids)}
        onAddClick={addNonExistingGroup}
      />
      {selectedGroups.length > 0 && (
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col gap-2 mt-3 rounded-lg bg-internationalBlue-10 border-[6px] border-highlightingBlue-5 p-4 overflow-y-scroll ">
            {selectedGroups?.map(({ id, name }) => (
              <div
                key={id}
                className={classNames(
                  String(id) == activeGroupId ? 'bg-highlightingBlue text-white' : 'bg-white text-darkBlue-60',
                  'px-2 h-10 w-full flex justify-between items-center border border-darkBlue-20 pointer',
                  'transition-colors duration-100'
                )}
                onClick={() => {
                  setActiveGroupId(String(id));
                }}
              >
                <span>{name}</span>
                <div
                  className="cursor-pointer hover:scale-110 transition-transform duration-200 active:scale-95"
                  onClick={() => removeGroupFromProfile(id.toString())}
                >
                  <Icon appearance={String(id) == activeGroupId ? 'white' : 'none'} type="close" />
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-2 mt-3 rounded-lg bg-internationalBlue-10 border-[6px] border-highlightingBlue-5 p-4 overflow-y-scroll ">
            {activeGroupId && (
              <PrinterProfileGroupPriorityList
                profileName={profileName}
                groups={selectedGroups}
                activeGroupId={activeGroupId}
                onReorder={onPriorityReorder}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
