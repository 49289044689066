import React, { ChangeEvent } from 'react';
import { useNumberFieldState } from 'react-stately';
import { AriaNumberFieldProps, useLocale, useNumberField } from 'react-aria';
import { Icon } from '../Icon';
import { Size, sizeToStyleMap, transformBetweenPlaceholderAndLabel, inputStyling } from './constants';
import classNames from 'classnames';
import { isNil } from 'lodash';
import { RequiredTag } from '../RequiredTag';

export interface NumberFieldProps extends AriaNumberFieldProps {
  size?: Size;
  showButtons?: boolean;
  description?: string;
  isDisabled?: boolean;
  name?: string;
  className?: string;
  inputClassName?: string;
  required?: boolean;
}

export const NumberField: React.FC<NumberFieldProps> = ({
  size = Size.Medium,
  label,
  description,
  showButtons = true,
  isDisabled,
  name,
  className,
  inputClassName,
  required,
  ...props
}) => {
  const inputRef = React.useRef(null);
  const { locale } = useLocale();
  const state = useNumberFieldState({ isDisabled, ...props, locale });

  const [isFocused, setIsFocused] = React.useState(false);

  const {
    labelProps,
    groupProps,
    inputProps,
    descriptionProps,
    incrementButtonProps,
    decrementButtonProps,
    errorMessageProps,
    isInvalid,
    validationErrors
  } = useNumberField({ isDisabled, label, description, ...props }, state, inputRef);

  const handleFocus = (event) => {
    setIsFocused(true);
    props.onFocus && props.onFocus(event);
  };

  const handleBlur = (event) => {
    setIsFocused(false);
    props.onBlur && props.onBlur(event);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    state.setNumberValue(parseInt(event.target.value) || 0);
  };

  return (
    <div className={classNames('relative w-fit group', className)}>
      <label
        {...labelProps}
        className={classNames(
          'absolute z-20 pointer-events-none h-fit',
          'text-ellipsis overflow-hidden whitespace-nowrap select-none',
          showButtons ? 'max-w-[calc(100%-26px)]' : 'max-w-[calc(100%-12px)]',
          'transition-all duration-200',
          transformBetweenPlaceholderAndLabel(
            !isNil(props.placeholder) || !isNil(state.numberValue),
            isDisabled,
            size,
            isFocused
          )
        )}
      >
        {label} {required && <RequiredTag />}
      </label>
      <div {...groupProps} className={classNames('relative flex w-fit', className)}>
        <input
          {...inputProps}
          name={name}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          ref={inputRef}
          className={classNames(
            sizeToStyleMap[size].inputStyle,
            inputStyling(isDisabled, isInvalid),
            isDisabled ? 'placeholder:text-darkBlue-30' : 'placeholder:text-darkBlue-40',
            inputClassName
          )}
        />
        {showButtons && (
          <div className="absolute flex flex-col justify-center h-full right-2">
            <a
              {...incrementButtonProps}
              className={classNames('flex', isDisabled ? 'cursor-not-allowed' : 'cursor-pointer', 'h-fit')}
              onClick={state.increment}
            >
              <Icon
                type="chevronUp"
                sizeClassName={size === Size.Small ? 'text-[10px] mb-1' : 'text-xs'}
                className={classNames('transition-transform', !isDisabled && 'hover:scale-110 active:scale-100')}
              />
            </a>

            <a
              {...decrementButtonProps}
              className={classNames('flex', isDisabled ? 'cursor-not-allowed' : 'cursor-pointer', 'h-fit')}
              onClick={state.decrement}
            >
              <Icon
                type="chevronDown"
                sizeClassName={size === Size.Small ? 'text-[10px]' : 'text-xs'}
                className={classNames('transition-transform', !isDisabled && 'hover:scale-110 active:scale-100')}
              />
            </a>
          </div>
        )}
      </div>
      {description && (
        <legend
          {...descriptionProps}
          className={classNames(
            'mt-2',
            isInvalid || validationErrors.length > 0 ? 'text-warningRed' : 'text-darkBlue-60',
            isDisabled && '!text-darkBlue-20',
            sizeToStyleMap[size].descriptionStyle
          )}
        >
          {description}
        </legend>
      )}
      {isInvalid && (
        <legend
          {...errorMessageProps}
          className={classNames('text-warningRed mt-2', sizeToStyleMap[size].descriptionStyle)}
        >
          {validationErrors.join(' ')}
        </legend>
      )}
    </div>
  );
};
