import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useButton, useOverlayTrigger, usePreventScroll } from 'react-aria';
import { Item, useOverlayTriggerState } from 'react-stately';

import { NewTextInput } from '@nshift/common/components/Input/NewTextInput';
import { Popover } from '@nshift/common/components/Modal/Popover';
import { GridList } from '@nshift/common/components/Select';
import { Icon } from '@nshift/common/components/Icon';

import { debounce } from 'lodash';

export interface ComboBoxProps {
  label?: string;
  selectedKeys?: any[];
  items?: any[];
  addButtonLabel?: string;
  onSelectionChange?: Dispatch<SetStateAction<Set<any>>>;
  allowCustomValues?: boolean;
  selectionMode?: 'single' | 'multiple';
  onAddClick?: (key: string) => void;
  required?: boolean;
}

const Button = (props) => {
  const ref = React.useRef(null);
  const { buttonProps } = useButton(props, ref);
  const { children } = props;

  return (
    <button {...buttonProps} ref={ref} className="flex justify-start w-full p-2">
      {children}
    </button>
  );
};

export const ComboBox: React.FC<ComboBoxProps> = ({
  label,
  selectedKeys,
  items,
  addButtonLabel = 'Add',
  allowCustomValues,
  selectionMode = 'single',
  onSelectionChange,
  onAddClick,
  required,
  ...props
}) => {
  const inputRef = React.useRef(null);
  const parentRef = React.useRef(null);
  const gridListRef = React.useRef(null);
  const popoverRef = React.useRef(null);

  const state = useOverlayTriggerState(props);
  const preventDefault = usePreventScroll();

  const [inputFieldValue, setInputFieldValue] = React.useState('');
  const [filteredItems, setFilteredItems] = React.useState([]);

  // Make sure that the dropdown has the same width as the input field.
  useEffect(() => {
    if (state.isOpen && gridListRef) {
      const inputWidth = inputRef.current.getBoundingClientRect().width;
      gridListRef.current.style.minWidth = `${inputWidth}px`;
    }
    items?.length > 0 && setFilteredItems(items);
  }, [parentRef, state.isOpen, items]);

  const { triggerProps, overlayProps } = useOverlayTrigger({ type: 'menu' }, state, popoverRef);

  const filterItems = (filterValue: String) => {
    if (filterValue.length === 0) {
      setFilteredItems(items);
      return;
    }

    const filteredItems = items?.filter((item) => {
      return item.name?.includes(filterValue);
    });
    setFilteredItems(filteredItems);
  };

  // Make it possible to add a new value to the list.
  const shouldShowAddNewButton = () => {
    const anyChildrenMatchInput = items?.some((item) => {
      return item.name === inputFieldValue;
    });
    return allowCustomValues && !anyChildrenMatchInput && inputFieldValue.length > 0;
  };

  const handleInputChange = debounce((event) => {
    const filterValue = event.target.value;
    setInputFieldValue(filterValue);
    filterItems(filterValue);
    preventDefault;
    state.open();
  }, 300);

  const onSelection = (key: SetStateAction<any>) => {
    if (selectionMode === 'single') {
      state.close();
    }
    onSelectionChange?.(key);
  };

  const handleAddClick = () => {
    if (onAddClick) {
      onAddClick(inputFieldValue);
    }
    state.close();
    clearInputText();
  };

  const clearInputText = () => {
    setInputFieldValue('');
  };

  const handleOpen = () => {
    preventDefault;
    state.open();
  };

  return (
    <div className="inline-flex flex-col">
      <div className="flex flex-col w-full" ref={parentRef}>
        <NewTextInput
          {...triggerProps}
          ref={inputRef}
          id="combobox-input"
          name="combobox-input"
          label={label}
          onFocus={handleOpen}
          onChange={handleInputChange}
          onClick={handleOpen}
          required={required}
        />
        {state.isOpen && (
          <Popover
            {...overlayProps}
            triggerRef={inputRef}
            popoverRef={popoverRef}
            state={state}
            className="p-2 bg-white"
          >
            {shouldShowAddNewButton() && (
              <Button onPress={handleAddClick}>
                <Icon type="add" />
                <span className="ml-2 mr-1 font-bold">{addButtonLabel}</span>
                {`${inputFieldValue}`}
              </Button>
            )}

            <GridList
              ref={gridListRef}
              items={filteredItems}
              selectionMode={selectionMode}
              selectedKeys={selectedKeys}
              onSelectionChange={onSelection}
            >
              {filteredItems?.map((item) => <Item key={item.id}>{item.name}</Item>)}
            </GridList>
          </Popover>
        )}
      </div>
    </div>
  );
};
