import * as React from 'react';
import { Button } from '../Button';
import { Icon } from '../Icon';
import classnames from 'classnames';

export const TableRowDetailsDrawer = ({ row, actionButtons, className = '', onClose, children }) => {
  return (
    <div
      className={classnames(
        'z-20 fixed bottom-0 right-0 w-80 bg-highlightingBlue-3 top-[104px] overflow-y-scroll shadow',
        className
      )}
    >
      <div className="relative flex h-full">
        <div>
          <Button className="mb-12 px-2 rounded-none h-full" onClick={onClose}>
            <Icon type="chevronRight" appearance="blue" sizeClassName="text-xs" aria-hidden="true" />
          </Button>
        </div>
        <div className="flex flex-col justify-between h-full w-full">
          <div className="mt-8">{children}</div>
          <div className="flex flex-col gap-4 pr-6 w-full py-4">
            {actionButtons?.map(({ id, label, handlerFn }) => (
              <Button
                key={id}
                onClick={() => {
                  handlerFn(row);
                }}
                appearance="tertiary"
              >
                {label}
              </Button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
