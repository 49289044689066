import * as yup from 'yup';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import React, { FC, useMemo, useState } from 'react';
import { DevTool } from '@hookform/devtools';
import { FormattedMessage } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';

import { urls } from '@/constants';
import { translations } from '@/locales';
import {
  getClientList,
  getPrinterProfileGroups,
  getPrinterList,
  createPrinterProfile,
  updatePrinterProfile
} from '@/services/api';

import { Button } from '@nshift/common/components/Button';
import { FormCombobox } from '@nshift/common/components/Select';
import { Accordion } from '@nshift/common/components/Accordion';
import { FormToggle } from '@nshift/common/components/FormToggle';
import { FormNumberField, FormTextField } from '@nshift/common/components/Input';

import { mockPrinterOptions } from './constants';
import { PrinterProfileGroups } from './PrinterProfileGroups';
import { PrinterOptionsSelector } from './PrinterOptionsSelector';
import { Printer, PrinterProfileFormData, PrinterProfileGroupsResponse, printFormat } from './types';
import toast from 'react-hot-toast/headless';

interface PrinterProfileFormProps {
  profileId?: string;
  defaultData?: any;
  key: any;
  editMode?: boolean;
}

const schema = yup.object().shape({});

export const PrinterProfileForm: FC<PrinterProfileFormProps> = ({ profileId, defaultData, key, editMode }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  console.log('defaultData', { defaultData });
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: defaultData,
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const [profileName, client, printerId] = useWatch({ control, name: ['profileName', 'clientId', 'printerId'] });
  const [errorMessage, setErrorMessage] = useState<string>();

  const { data: { clients = [] } = {} } = useQuery<{ clients: Array<{ clientId: number; name: string }> }>(
    ['get-clients'],
    getClientList(0, 100),
    { refetchOnWindowFocus: false }
  );

  const { data: { printers = [] } = {} } = useQuery<{
    printers: Array<Printer>;
  }>(['get-printers', client], getPrinterList(0, 1000, client?.length > 0 ? [Number(client)] : []), {
    refetchOnWindowFocus: false
  });

  const handleProfileCreate = (data: PrinterProfileFormData) => {
    createPrinterProfile(data)
      .then(() => {
        navigate(urls.printers.profiles.base);
        toast.success(intl.formatMessage({ id: translations.pages.printerProfiles.toasts.createSuccess }));
      })
      .catch((error) => {
        const regex = /"detail":"([^"]+)"/;
        const matches = error.response?.data?.detail?.match(regex);
        if (matches?.length > 1) {
          const errorDetail = matches[1];
          setErrorMessage(errorDetail);
        } else {
          setErrorMessage(error.response?.data?.detail);
        }
      });
  };
  const handleProfileUpdate = (data: PrinterProfileFormData) => {
    updatePrinterProfile(Number(profileId), data)
      .then(() => {
        navigate(urls.printers.profiles.base);
        toast.success(intl.formatMessage({ id: translations.pages.printerProfiles.toasts.updateSuccess }));
      })
      .catch((error) => {
        const regex = /"detail":"([^"]+)"/;
        const matches = error.response?.data?.detail?.match(regex);
        if (matches?.length > 1) {
          const errorDetail = matches[1];
          setErrorMessage(errorDetail);
        } else {
          setErrorMessage(error.response?.data?.detail);
        }
      });
  };

  const onSubmit = handleSubmit((data) => {
    setErrorMessage(undefined);
    if (editMode) {
      handleProfileUpdate(data);
    } else {
      handleProfileCreate(data);
    }
  });

  const medias = useMemo(() => {
    return (
      printers
        ?.find((printer) => printerId?.includes(printer.printerId.toString()))
        ?.medias?.map((media) => ({ id: media, name: media })) ?? []
    );
  }, [printerId]);

  const printerOptions = useMemo(() => {
    const options =
      printers.find((printer) => printerId?.includes(printer.printerId.toString()))?.options ?? mockPrinterOptions;

    return options;
  }, [printers, printerId]);

  const { data: { groups: printerProfileGroups = [] } = {} } = useQuery<PrinterProfileGroupsResponse>(
    ['get-printer-profile-groups'],
    getPrinterProfileGroups,
    { refetchOnWindowFocus: false }
  );

  return (
    <div>
      <DevTool control={control} />
      <form
        className="flex flex-col gap-4 mx-8"
        onSubmit={onSubmit}
        key={key}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <Accordion id="ProfileIntro" isOpen renderTitle="Intro">
          <div className="grid grid-cols-2 gap-4">
            <FormTextField
              errors={errors}
              id="profileName"
              name="profileName"
              control={control}
              label={intl.formatMessage({ id: translations.pages.printerProfiles.columnsFilters.profileName })}
              inputClassName="w-full"
              className="w-full"
              isRequired
            />
            <FormToggle
              name="active"
              register={register}
              label={intl.formatMessage({ id: translations.domains.printerProfilesStatusFilter.ACTIVE })}
            />
          </div>
        </Accordion>

        <Accordion id="ProfileGroups" isOpen renderTitle="Profile Groups">
          <PrinterProfileGroups
            profileName={profileName}
            initialGroups={printerProfileGroups?.map(({ id, groupName, printerProfileAndPriorities }) => ({
              id: id,
              name: groupName,
              profilesAndPriorities: printerProfileAndPriorities
            }))}
          />
        </Accordion>
        {clients?.length > 0 && printers?.length > 0 && (
          <Accordion id="PrinterDetails" isOpen renderTitle="Printer Details">
            <div className="grid grid-cols-2 gap-4">
              <FormCombobox
                items={clients?.map(({ clientId, name }) => ({ id: clientId.toString(), name }))}
                label={intl.formatMessage({ id: translations.domains.commonColumnsFilters.client })}
                control={control}
                name="clientId"
                required
              />
              <FormCombobox
                items={printers?.map(({ printerId, name }) => ({ id: printerId.toString(), name }))}
                label={intl.formatMessage({ id: translations.domains.commonColumnsFilters.printer })}
                control={control}
                name="printerId"
                required
              />
              {medias?.length > 0 && (
                <FormCombobox
                  items={medias}
                  label={intl.formatMessage({ id: translations.pages.printerProfiles.columnsFilters.media })}
                  control={control}
                  name="media"
                  required
                />
              )}
              <FormCombobox
                items={printFormat}
                label={intl.formatMessage({ id: translations.pages.printerProfiles.columnsFilters.format })}
                control={control}
                name="format"
                required
              />
              <FormNumberField
                id="offsetX"
                name="offsetX"
                control={control}
                label={intl.formatMessage({ id: translations.pages.printerProfiles.columnsFilters.offsetX })}
                inputClassName="w-full"
                className="w-full"
                maxValue={100}
                minValue={-100}
              />
              <FormNumberField
                id="offsetY"
                name="offsetY"
                control={control}
                label={intl.formatMessage({ id: translations.pages.printerProfiles.columnsFilters.offsetY })}
                inputClassName="w-full"
                className="w-full"
                maxValue={100}
                minValue={-100}
              />
              <FormTextField
                errors={errors}
                id="layouts"
                name="layouts"
                control={control}
                label={intl.formatMessage({ id: translations.pages.printerProfiles.columnsFilters.layouts })}
                inputClassName="w-full"
                className="w-full"
              />
            </div>

            {printerOptions.length > 0 && <PrinterOptionsSelector {...{ printerOptions, control }} />}
          </Accordion>
        )}
        <div className="text-warningRed">{errorMessage}</div>
        <div className="flex justify-end col-span-2">
          <Button linkTo={urls.printers.profiles.base} className="w-32 ml-4" appearance="secondary">
            <FormattedMessage id={translations.application.buttons.cancel} />
          </Button>
          <Button className="ml-4 w-52" appearance="primary" type="submit" isLoading={isSubmitting}>
            <FormattedMessage id={translations.application.buttons.save} />
          </Button>
        </div>
      </form>
    </div>
  );
};

// Controller for SimpleCombobox alternative method

// <Controller
//   name={'single'}
//   control={control}
//   defaultValue={[mock[4].id]}
//   render={({ field }) => <SimpleCombobox selectionMode="single" {...field} items={mock} label="Single" />}
// />

// <Controller
//   name={'multiple'}
//   control={control}
//   defaultValue={[mock[3].id, mock[5].id, mock[7].id]}
//   render={({ field }) => <SimpleCombobox selectionMode="multiple" {...field} items={mock} label="Multiple" />}
// />

// Mock data for SimpleCombobox
/* const mock = faker.helpers.multiple(
  () => ({
    id: faker.string.uuid(),
    name: faker.location.city()
  }),
  { count: 10 }
);
*/
