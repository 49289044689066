import { PrinterGroup } from './types';

export const getActiveGroup = (selectedGroups: PrinterGroup[], activeGroupId: string | null) => {
  return (
    selectedGroups.find((group) => activeGroupId === String(group.id) || activeGroupId === tempProfileName(group.id)) ||
    null
  );
};

export const tempProfileName = (groupId: number) => {
  return `tempProfileName${groupId}`;
};
