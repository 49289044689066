import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { urls } from '@/constants';

import { Header, Footer } from '@/components/App';
import { ClientSetupPage } from '@/pages/Client';
import PrintJobsPage from '@/pages/PrintJobs/PrintJobsPage';
import { AuthBasePage } from '@/pages/AuthPages/AuthBasePage';
import { ActivatePage } from '@/pages/AuthPages/ActivatePage';
import { PrinterProfileDetailPage } from '@/pages/PrinterProfiles';
import PrinterProfilesPage from '@/pages/PrinterProfiles/PrinterProfilesPage';
import { ResetPasswordPage, SignInPage, ForgotPasswordPage } from '@/pages/AuthPages';

import { Session } from '@/containers/SessionContainer';
import { SimpleFooter } from './SimpleFooter';

const headerlessUrls = [
  urls.signIn,
  urls.signUp,
  urls.activate,
  urls.forgotPassword,
  urls.resetPassword.main,
  urls.resetPassword.expired
];

const simpleFooterRoutes = [
  urls.signIn,
  urls.signUp,
  urls.activate,
  urls.forgotPassword,
  urls.resetPassword.main,
  urls.resetPassword.expired
];

export const AppContent: React.FC = () => {
  const { authenticated } = Session.useContainer();

  const isProduction = process.env.NODE_ENV !== 'development';

  return (
    <React.Fragment>
      <Routes>
        {headerlessUrls.map((path) => (
          <Route key={path} {...{ path }} element={null} />
        ))}

        <Route path="*" element={<Header />} />
      </Routes>

      {authenticated ? (
        <div className="relative flex w-full pt-20 mx-auto bg-gray-200 min-h-fit max-w-7xl">
          <Routes>
            {!isProduction && <Route path={urls.setUp.clients} element={<ClientSetupPage />} />}

            {!isProduction && <Route path={urls.printJobs.base} element={<PrintJobsPage />} />}

            <Route path={urls.printers.profiles.base} element={<PrinterProfilesPage />} />

            <Route path={urls.printers.profiles.create} element={<PrinterProfileDetailPage isEdit={false} />} />

            <Route path={urls.printers.profiles.detail} element={<PrinterProfileDetailPage isEdit />} />

            <Route path="*" element={<Navigate to={urls.overview} />} />
          </Routes>
        </div>
      ) : (
        <AuthBasePage>
          <Routes>
            <Route path={urls.signIn} element={<SignInPage />} />

            <Route path={urls.activate} element={<ActivatePage />} />

            <Route path={urls.forgotPassword} element={<ForgotPasswordPage />} />

            <Route path={urls.resetPassword.main} element={<ResetPasswordPage />} />

            <Route path="*" element={<Navigate to={urls.signIn} />} />
          </Routes>
        </AuthBasePage>
      )}

      <Routes>
        {simpleFooterRoutes.map((path) => (
          <Route key={path} {...{ path }} element={<SimpleFooter />} />
        ))}

        <Route path="*" element={<Footer />} />
      </Routes>
    </React.Fragment>
  );
};
