import React from 'react';
import { useQuery } from 'react-query';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { translations } from '@/locales';
import { getPrinterProfileDetail } from '@/services/api';
import { printerProfileToFormData } from '@/util/adapters';

import { LoadingSize, Spinner, SpinnerColor } from '@nshift/common/components/Loading';

import { PrinterProfileForm } from './PrinterProfileForm';
import { InitialPrinterProfileFormData } from './types';

export const PrinterProfileDetailPage: React.FC<{ isEdit: boolean }> = ({ isEdit }) => {
  const { id } = useParams<{ id: string }>();

  const { isLoading, data } = useQuery<InitialPrinterProfileFormData>(
    'get-printer-profile',
    () => getPrinterProfileDetail(Number(id)).then(printerProfileToFormData),
    { enabled: isEdit }
  );

  return (
    <div className="w-full py-20">
      <div className="mx-6 mb-8 text-3xl font-semibold">
        <FormattedMessage id={translations.pages.printerProfiles.details.title} />
      </div>

      <div className="flex flex-col py-6 mx-6 bg-white rounded-lg shadow">
        <div className="pb-4 mx-8 mt-2 text-xl font-semibold border-b text-internationalBlue mb-9 border-darkBlue-20">
          <FormattedMessage
            id={
              isEdit ? translations.pages.printerProfiles.edit.title : translations.pages.printerProfiles.create.title
            }
          />
        </div>

        {isLoading ? (
          <Spinner isIndeterminate size={LoadingSize.Medium} fillColor={SpinnerColor.Primary} />
        ) : (
          <PrinterProfileForm defaultData={isEdit ? data : {}} key={id} editMode={isEdit} profileId={id ?? '0'} />
        )}
      </div>
    </div>
  );
};
