import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

// import { dateFormatter } from '@nshift/common/constants/dateTime';
import { translations } from '@/locales';
import { PrinterProfileStatusBadge } from './PrinterProfileStatusBadge';
import { getStatus } from './PrinterProfilesTableConfig';
import classNames from 'classnames';
import { dateFormatter } from '@nshift/common/constants/dateTime';

export const PrinterProfileDetailsView: FC<{ row: any }> = ({ row }) => {
  return (
    <div className="flex flex-col px-4">
      <div className="mb-8 text-darkBlue font-semibold text-lg">
        <FormattedMessage id={translations.pages.printerProfiles.details.title} />
      </div>

      <div className="flex flex-col gap-4 text-darkBlue-60 text-sm">
        <div className="flex justify-between items-center">
          <div className="semibold">
            <FormattedMessage id={translations.pages.printerProfiles.columnsFilters.id} />:
          </div>
          <div>{row.id ?? '-'}</div>
        </div>

        <div className="flex justify-between">
          <div className="semibold">
            <FormattedMessage id={translations.pages.printerProfiles.columnsFilters.profileName} />:
          </div>
          <div className="max-w-[150px] text-right break-all">{row.name ?? '-'}</div>
        </div>

        <div className="flex justify-between items-center">
          <div className="semibold">
            <FormattedMessage id={translations.domains.commonColumnsFilters.status} />:
          </div>
          <PrinterProfileStatusBadge status={getStatus(row)} className="pt-23.5" />
        </div>

        <div
          className={classNames(
            'flex',
            row.printerProfileGroups?.length > 1 ? 'flex-col' : 'justify-between items-center'
          )}
        >
          <div className="semibold whitespace-break-spaces max-w-20">
            <FormattedMessage id={translations.domains.commonColumnsFilters.profileGroups} />:
          </div>
          {row.printerProfileGroups?.length > 1 ? (
            <div className="flex flex-col gap-2 mt-3 rounded-lg bg-internationalBlue-10 border-1.5 border-highlightingBlue-5 py-4 px-2 overflow-y-scroll">
              {row.printerProfileGroups?.map(({ id, name }: { [key: string]: string }) => (
                <div
                  key={id}
                  className="px-2 bg-white h-10 w-full flex justify-center items-center text-darkBlue-60 border border-darkBlue-20"
                >
                  <span className="break-all">{name}</span>
                </div>
              ))}
            </div>
          ) : row.printerProfileGroups?.length === 1 ? (
            <span className="break-all">
              {row.printerProfileGroups[0].name ?? row.printerProfileGroups[0].id ?? '-'}
            </span>
          ) : (
            <>
              <div className="semibold whitespace-break-spaces max-w-20">
                <FormattedMessage id={translations.domains.commonColumnsFilters.profileGroups} />:
              </div>
              <span> - </span>
            </>
          )}
        </div>

        <div className="flex justify-between items-center">
          <div className="semibold">
            <FormattedMessage id={translations.domains.commonColumnsFilters.client} />:
          </div>
          <div className="max-w-[150px] text-right break-all">{row.clientName ?? '-'}</div>
        </div>

        <div className="flex justify-between items-center">
          <div className="semibold">
            <FormattedMessage id={translations.domains.commonColumnsFilters.printer} />:
          </div>
          <div className="max-w-[150px] text-right">{row.printerName ?? '-'}</div>
        </div>

        <div className="flex justify-between items-center">
          <div className="semibold">
            <FormattedMessage id={translations.domains.commonColumnsFilters.printerId} />:
          </div>
          <div>{row.printerId ?? '-'}</div>
        </div>
        <div className="flex justify-between items-center">
          <div className="semibold">
            <FormattedMessage id={translations.pages.printerProfiles.columnsFilters.media} />:
          </div>
          <div>{row.media ?? '-'}</div>
        </div>

        <div className="flex justify-between items-center">
          <div className="semibold">
            <FormattedMessage id={translations.pages.printerProfiles.columnsFilters.format} />:
          </div>
          <div>{row.format ?? '-'}</div>
        </div>

        <div className="flex justify-between items-center">
          <div className="semibold">
            <FormattedMessage id={translations.domains.commonColumnsFilters.created} />:
          </div>
          <div>{dateFormatter(row.created)}</div>
        </div>
        <div className="flex justify-between items-center">
          <div className="semibold">
            <FormattedMessage id={translations.domains.commonColumnsFilters.changed} />:
          </div>
          <div>{dateFormatter(row.updated)}</div>
        </div>
      </div>
    </div>
  );
};
