import { authorizedApi } from '@/services/network';
import { printerFormDataToRequestBody } from '@/util/adapters';

// TODO move these types into the domains folder in another PR
import { PrinterProfileFormData } from '@/pages/PrinterProfiles/types';

export const getPrinterProfiles = () => {
  return authorizedApi.get<any>('/printer-profiles');
};

export const createPrinterProfile = (data: PrinterProfileFormData) => {
  return authorizedApi.post<any>('/printer-profiles', printerFormDataToRequestBody(data));
};

export const updatePrinterProfile = (profileId: number, data: PrinterProfileFormData) => {
  return authorizedApi.patch<any>(`/printer-profiles/${profileId}`, printerFormDataToRequestBody(data));
};

export const getPrinterProfileDetail = (id: number) => authorizedApi.get<any>(`/printer-profiles/${id}`);

export const getPrinterProfileGroups = () => {
  return authorizedApi.get<any>('/printer-profiles/groups');
};

export const deletePrinterProfile = (id: number) => {
  return authorizedApi.delete<any>(`/printer-profiles/${id}`);
};
