import React from 'react';

import { Dropdown, DropdownMenuItem } from './HeaderDropdown';

import { Icon } from '@nshift/common/components/Icon';

import { LanguageContainer } from '../Context/LanguageContext';
import { FormattedMessage } from 'react-intl';
import { translations } from '@/locales';

export const SlimHeader: React.FC = () => {
  const { language, languages, update } = LanguageContainer.useContainer();

  return (
    <div className="relative z-20 flex items-center w-full h-8 px-8 text-sm divide-x bg-darkBlue-5 divide-darkBlue-20">
      <a className="px-2 ml-auto" href="/webshop/something">
        <FormattedMessage id={translations.application.header.slimHeader.webshop} />
      </a>

      <a href="/support" className="px-2">
        <FormattedMessage id={translations.application.header.slimHeader.support} />
      </a>

      <div className="pl-2">
        <Dropdown
          className="right-0 "
          button={
            <div className="flex items-center cursor-pointer">
              {language.toUpperCase()}
              <Icon type="chevronDown" appearance="none" className="ml-1 " sizeClassName="text-[10px] leading-[10px]" />
            </div>
          }
        >
          {languages.map((languageItem) => (
            <DropdownMenuItem
              key={languageItem}
              active={language === languageItem}
              onClick={() => update(languageItem)}
            >
              <div>{languageItem.toUpperCase()}</div>
            </DropdownMenuItem>
          ))}
        </Dropdown>
      </div>
    </div>
  );
};
