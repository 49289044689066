import React, { FC } from 'react';
import { FormCombobox } from '@nshift/common/components/Select';
import { Control, FieldValues } from 'react-hook-form';
import { PrinterOption } from './types';

interface Props {
  control: Control<FieldValues>;
  printerOptions: Array<PrinterOption>;
}

export const PrinterOptionsSelector: FC<Props> = ({ control, printerOptions }) => {
  return (
    <div className="mt-6">
      <div className="mb-4">Printer options</div>
      {printerOptions.map(({ key, values }) => (
        <div key={key} className="grid grid-cols-2">
          <div className="flex items-center px-4 text-sm capitalize bg-white border border-darkBlue-40 text-darkBlue-70">
            {key}
          </div>
          <div className="flex items-center justify-start bg-white border border-darkBlue-40 min-h-10 hover:bg-highlightingBlue-10">
            <FormCombobox
              items={values.map((value) => ({ id: value.toString(), name: value.toString() }))}
              control={control}
              name={`printerOptions.${key}`}
              className="w-full"
              noBorder
            />
          </div>
        </div>
      ))}
    </div>
  );
};
