import React from 'react';

import { DraggableList } from '@nshift/common/components/DraggableList';

import type { PrinterGroup } from './priorities/types';
import { tempProfileName } from './priorities/utils';

import { Item } from 'react-stately';

export interface PrinterProfileGroupPriorityListProps {
  groups?: Array<PrinterGroup>;
  activeGroupId: string;
  profileName?: string;
  onReorder?: (newPriority: string | number, updatedPriorityList: Array<{ name: string; priority: number }>) => void;
}

export const PrinterProfileGroupPriorityList: React.FC<PrinterProfileGroupPriorityListProps> = ({
  groups,
  activeGroupId,
  profileName,
  onReorder
}) => {
  const [priorityOrder, setPriorityOrder] = React.useState<Array<{
    name: string;
    key: string;
    priority: number;
  }> | null>();

  React.useEffect(() => {
    const mappedPriorityOrder = groups
      ?.find(({ id }) => [String(id), tempProfileName(id)].includes(activeGroupId))
      ?.profilesAndPriorities?.map(({ name, priority }) => {
        return { name, key: name, priority };
      });

    setPriorityOrder(mappedPriorityOrder);
  }, [groups, activeGroupId, profileName]);

  const disabledPriorityKeys = priorityOrder?.reduce((acc, filteredPriority) => {
    if (filteredPriority.name !== profileName) {
      acc.push(filteredPriority.key);
    }
    return acc;
  }, [] as string[]);

  const handleReorder = (e, updatedPriorityList) => {
    // Which element was it placed before/after?
    const { target } = e;

    const newTargetPriority = calculatePriority(target.dropPosition, target.key);
    onReorder?.(newTargetPriority, updatedPriorityList);
  };

  /**
   * The API handles the priority calculation in a very specific way.
   * Priorities can at minimum be 0, and at maximum n.
   * However, the priority does not necessarily have to start at 0, and can instead start at any number.
   * If the lowest priority is 4, and and item is dragged to the top, the new priority will be 3.
   * The priorities can skip numbers meaning that the priorities can be e.g. 0, 1, 3, 11, 20.
   */
  const calculatePriority = (dropPosition: 'before' | 'after' | 'on', targetKey: string) => {
    // First lets find the target priority
    const targetPriority = priorityOrder?.find((priority) => priority.key === targetKey) || { priority: 0 };

    switch (dropPosition) {
      case 'before':
        return targetPriority.priority + 1;
      case 'on':
        return targetPriority?.key;
      case 'after':
        if (targetPriority.priority === 0) return 0;
        return targetPriority.priority - 1;
      default:
        return targetPriority.priority;
    }
  };

  return (
    <>
      {priorityOrder && (
        <DraggableList items={priorityOrder} disabledKeys={disabledPriorityKeys} onReorder={handleReorder}>
          {(item) => <Item>{item.name}</Item>}
        </DraggableList>
      )}
    </>
  );
};
