import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import { Icon } from '../Icon';

export interface AccordionProps {
  id: string;
  renderTitle?: React.ReactNode;
  onToggle?: (toggleState: boolean) => void;
  children: React.ReactNode;
  isOpen?: Boolean;
  className?: string;
}

export const Accordion: React.FC<AccordionProps> = ({
  renderTitle,
  onToggle,
  children,
  isOpen = false,
  id: accordionId,
  className
}) => {
  const [open, setOpen] = useState<Boolean>(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleToggle = (event: MouseEvent) => {
    event.preventDefault();
    setOpen((currentOpen) => {
      onToggle?.(!currentOpen);
      return !currentOpen;
    });
  };

  return (
    <div
      className={classnames(
        open ? 'bg-internationalBlue-5' : '',
        'rounded',
        'transition-colors duration-200 ease-in-out',
        className
      )}
      aria-expanded={!!open}
      id={`${accordionId}-button`}
    >
      <h3>
        <button
          className="flex justify-between w-full px-6 py-3 cursor-pointer"
          onClick={handleToggle}
          aria-controls={`${accordionId}-content`}
          role="button"
          tabIndex={0}
        >
          <span className="flex flex-1">{renderTitle}</span>
          <Icon
            aria-hidden="true"
            className="ml-3"
            type={open ? 'chevronUp' : 'chevronDown'}
            sizeClassName="text-base"
          />
        </button>
      </h3>
      {!open && <hr className="mx-3 border-t-darkBlue-30" />}
      {open && (
        <section
          className="px-6 py-3"
          role="region"
          id={`${accordionId}-content`}
          aria-labelledby={`${accordionId}-button`}
        >
          {children}
        </section>
      )}
    </div>
  );
};
