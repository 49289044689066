// TODO move these types into the domains folder in another PR
import {
  FormPrinterOptions,
  InitialPrinterProfileFormData,
  PrinterOptionsInRequest,
  PrinterProfile,
  PrinterProfileCreateRequestBody,
  PrinterProfileFormData,
  PrintFormat
} from '@/pages/PrinterProfiles/types';
import { isNil } from 'lodash';

export const adaptLayouts = (layoutsString?: string): string[] | undefined => {
  if (layoutsString) {
    return layoutsString.split(',').map((str) => str.trim());
  }
};

export const adaptPrinterOptions = (options: FormPrinterOptions): Array<PrinterOptionsInRequest> => {
  return Object.entries(options)
    .map(
      ([key, value]) => ({ key, value: value?.length > 0 ? String(value) : null }) as unknown as PrinterOptionsInRequest
    )
    .filter(({ value }) => !isNil(value)); // Filter out options with null values
};

export const printerFormDataToRequestBody = (data: PrinterProfileFormData): PrinterProfileCreateRequestBody => {
  const {
    printerId = [],
    active,
    profileName,
    printerGroups,
    format = [],
    media = [],
    offsetX,
    offsetY,
    layouts,
    printerOptions
  } = data;

  // TODO discuss maybe having these mappings in the handleSubmit function, so we don't have too much code breakup
  return {
    printerId: printerId.length > 0 ? Number(printerId[0]) : 0,
    active,
    name: profileName,
    printerGroups,
    printerRender: {
      format: format.length > 0 ? (format[0] as PrintFormat) : ('PDF' as PrintFormat),
      media: media.length > 0 ? media[0] : 'laser-a4',
      offsetX,
      offsetY,
      layouts: adaptLayouts(layouts),
      options: adaptPrinterOptions(printerOptions)
    }
  };
};

export const printerProfileToFormData = (data: PrinterProfile): InitialPrinterProfileFormData => {
  return {
    profileName: data.name,
    groups: data.groups,
    printer: [data.printerId?.toString()],
    clientId: [data.clientId?.toString()],
    active: data.active,
    format: [data.format],
    media: [data.media],
    offsetX: data.offsetX,
    offsetY: data.offsetY,
    layouts: data.layouts?.map(String).join(',').replace(/\s+/g, ''),
    printerOptions: data.options?.reduce((obj: FormPrinterOptions, item) => {
      obj[item.key] = [item.value];
      return obj;
    }, {} as FormPrinterOptions)
  };
};
