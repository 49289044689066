import React, { FC, useEffect, useState } from 'react';
import { Me } from 'app/src/domains/Me';
import classNames from 'classnames';

interface Props {
  me: Me;
  rounded?: boolean;
  outline?: boolean;
  className?: string;
}

export const UserImage: FC<Props> = ({ className, outline, rounded, me }) => {
  const [isPictureError, setIsPictureError] = useState(false);
  const { pictureUrl, name } = me;

  useEffect(() => {
    setIsPictureError(false);
  }, [me]);

  const userPictureError = () => {
    setIsPictureError(true);
  };

  return (
    <div
      className={classNames(
        `w-10 h-10 flex items-center justify-center overflow-hidden text-white shadow`,
        rounded ? 'rounded-full' : 'rounded-3xl',
        outline ? 'border-[3px] border-solid border-white' : 'border-none',
        (!pictureUrl || isPictureError) && 'bg-gradient-to-br from-internationalBlue to-nordicGreen',
        className
      )}
    >
      {pictureUrl && !isPictureError ? (
        <img
          src={pictureUrl}
          alt={`${name} Profile avatar`}
          onError={userPictureError}
          className="object-cover object-center w-full h-full"
        />
      ) : (
        name.charAt(0).toUpperCase()
      )}
    </div>
  );
};
