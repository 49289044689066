import React from 'react';
import classnames from 'classnames';
import { Icon, Type } from './Icon';
import { isNil } from 'lodash';

export type Appearance = 'lightBlue' | 'blue' | 'green' | 'yellow' | 'red' | 'pink' | 'lightGray' | 'gray' | 'black';

const appearanceStyles: Record<Appearance, { badge: string, icon: string }> = {
  lightBlue: { badge: 'bg-internationalBlue-10 text-highlightingBlue', icon: 'text-highlightingBlue' },
  blue: { badge: 'bg-highlightingBlue text-white', icon: 'text-white' },
  green: { badge: 'bg-confirmingGreen-10 text-confirmingGreen-STRONG', icon: 'text-confirmingGreen-STRONG' },
  yellow: { badge: 'bg-alertingYellow-10 text-alertingYellow-STRONG', icon: 'text-alertingYellow-STRONG' },
  red: { badge: 'bg-warningRed-10 text-warningRed-STRONG', icon: 'text-warningRed-STRONG' },
  pink: { badge: 'bg-pink-10 text-pink-STRONG', icon: 'text-pink-STRONG' },
  lightGray: { badge: 'bg-internationalBlue-5 text-darkBlue-80', icon: 'text-darkBlue-80' },
  gray: { badge: 'bg-internationalBlue-10 text-darkBlue', icon: 'text-darkBlue' },
  black: { badge: 'bg-darkBlue text-white', icon: 'text-white' },
};

interface DynamicElementProps extends React.PropsWithChildren {
  element?: keyof JSX.IntrinsicElements;
}

export interface Props extends DynamicElementProps {
  appearance?: Appearance;
  clickable?: boolean;
  className?: string;
  icon?: Type;
}

export const Badge: React.FC<Props> = ({
  appearance = 'gray',
  clickable = true,
  className,
  children,
  element,
  icon = null,
  ...rest
}) => {
  const Component = element || 'span';

  return (
    <Component
      className={classnames(
        'group text-sm h-6 px-3 py-1 transition-colors rounded-full box-border text-nowrap',
        appearanceStyles[appearance] ? appearanceStyles[appearance].badge : appearanceStyles['gray'].badge,
        clickable && 'cursor-pointer',
        className
      )}
      {...rest}
    >
      {!isNil(icon) && (
        <Icon
          type={icon}
          sizeClassName="text-[18px]"
          iconColor={appearanceStyles[appearance].icon}
          className="mb-1 mr-1"
        />
      )}
      {children}
    </Component>
  );
};
